<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="card card-custom">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Daftar Bank Soal
              </span>
              <!--              <b-skeleton width="100%" v-if="isLoading"></b-skeleton>-->
              <!--              <span class="text-muted mt-3 font-weight-bold font-size-sm" v-if="!isLoading && currentBankSoalList[0]">{{ currentSoalList[0].ujian.nama }}</span>-->
            </h3>
            <div class="card-toolbar">
              <span
                @click="modalImport = true"
                class="btn btn-warning font-weight-bolder font-size-sm mr-2"
              >
                Import Bank Soal</span
              >
              <router-link
                :to="`/bank-soal/soal/create`"
                href="#"
                class="btn btn-success font-weight-bolder font-size-sm"
              >
                Tambah Bank Soal Baru</router-link
              >
            </div>
          </div>
          <div class="card-body pt-4">
            <!--            <b-row class="justify-content-between align-items-center mb-4">-->
            <!--              <b-col cols="auto">-->
            <!--                <a-->
            <!--                  href="#"-->
            <!--                  class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Daftar Soal Ujian</a>-->
            <!--              </b-col>-->
            <!--              <b-col cols="auto">-->
            <!--                <a href="#" class="btn btn-success font-weight-bolder font-size-sm">-->
            <!--    &lt;!&ndash;              <span class="svg-icon svg-icon-md svg-icon-white">&ndash;&gt;-->
            <!--    &lt;!&ndash;                &lt;!&ndash;begin::Svg Icon | path:assets/media/svg/icons/Communication/Add-user.svg&ndash;&gt;&ndash;&gt;-->
            <!--    &lt;!&ndash;                <inline-svg src="media/svg/icons/Communication/Add-user.svg" />&ndash;&gt;-->
            <!--    &lt;!&ndash;                &lt;!&ndash;end::Svg Icon&ndash;&gt; </span>&ndash;&gt;-->
            <!--                  Tambah Soal Baru</a-->
            <!--                >-->
            <!--              </b-col>-->
            <!--            </b-row>-->
            <b-row class="justify-content-between">
              <b-col cols="auto">
                <b-form-group>
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-table
              class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-borderless
                card-table
              "
              :filter="filter"
              :items="currentBankSoalList.data"
              :fields="fields"
              :busy="isLoading"
              @filtered="onFiltered"
              show-empty
              responsive
            >
              <!--              tbody-tr-class="card card-table-row card-custom card-stretch gutter-b"-->
              <template #empty="scope">
                <h6 class="text-center">Tidak ada soal ditemukan</h6>
              </template>
              <template #emptyfiltered="scope">
                <h6 class="text-center">Tidak ada soal ditemukan</h6>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(index)="row">
                {{ perPage * (currentPage - 1) + (row.index + 1) }}
              </template>
              <template #row-details="row">
                <div class="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                  Soal:
                </div>
                <div v-html="row.item.question"></div>
              </template>
              <template #cell(soal)="data">
                <div
                  v-html="data.item.question"
                  style="
                    max-width: 200px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                  "
                ></div>
              </template>
              <template #cell(kode_soal)="data">
                {{ data.item.quetion_code }}
              </template>
              <template #cell(tipe)="data">
                <span
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-info
                    mr-1
                  "
                >
                  {{ data.item.type }}
                </span>
              </template>
              <template #cell(bobot)="data">
                <!--          <b-badge class="mr-1" pill href="#" variant="info" v-for="topik in data.item.topik" :key="topik">-->
                <!--            {{ topik }}-->
                <!--          </b-badge>-->
                <span
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-info
                    mr-1
                  "
                >
                  {{ data.item.bobot }} pts
                </span>
              </template>
              <template #cell(topic)="data">
                <!--                <b-badge class="mr-1" pill href="#" variant="info" v-for="topik in data.item.topik" :key="topik">-->
                <!--                  {{ topik }}-->
                <!--                </b-badge>-->
                <span
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-info
                    mr-1
                  "
                  v-for="topik in data.item.topic"
                  :key="topik"
                >
                  {{ topik }}
                </span>
              </template>
              <template #cell(action)="data">
                <span
                  @click="data.toggleDetails"
                  class="custom-v-dropdown btn btn-light-info btn-icon mr-1"
                >
                  <!--                  {{ data.detailsShowing ?'Sembunyikan':'Lihat Deskripsi' }}-->
                  <i
                    :class="`ki ki-bold-arrow-${
                      data.detailsShowing ? 'up' : 'down mt-1'
                    } icon-sm`"
                  ></i>
                </span>
                <button
                  @click="viewJawaban(data.item)"
                  class="
                    btn btn-light-primary
                    font-weight-bolder font-size-sm
                    mr-1
                  "
                  :disabled="data.item.type =='essay' "
                  >Daftar Jawaban</button
                >
                <!--                <span-->
                <!--                    class="btn btn-icon btn-info btn-hover-info shadow-sm mr-1"-->
                <!--                    v-b-tooltip.hover.right="'Lihat Soal Lengkap'"-->
                <!--                    @click="data.toggleDetails">-->
                <!--&lt;!&ndash;                  {{ row.detailsShowing ? 'Hide' : 'Show'}} Details&ndash;&gt;-->
                <!--                  <i :class="`ki ki-bold-arrow-${data.detailsShowing ?'up':'down'} icon-sm`"></i>-->
                <!--                </span>-->
                <!--                <span-->
                <!--                      @click="viewJawaban(data.item)"-->
                <!--                      class="btn btn-icon btn-primary btn-hover-primary shadow-sm mr-1"-->
                <!--                      v-b-tooltip.hover.right="'Lihat Pilihan Jawaban'"-->
                <!--                  >-->
                <!--                    <i class="ki ki-bold-arrow-next icon-sm"></i>-->
                <!--                </span>-->
                <b-dropdown
                  variant="light"
                  toggle-class="custom-v-dropdown btn btn-light btn-hover-light-primary btn-icon"
                  no-caret
                  right
                  no-flip
                >
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <!--begin::Navigation-->
                  <div class="navi navi-hover min-w-md-250px">
                    <b-dropdown-text tag="div" class="navi-item">
                      <router-link
                        class="navi-link"
                        :to="`/bank-soal/${data.item._id}/edit`"
                      >
                        <span class="navi-icon">
                          <i class="flaticon2-edit"></i>
                        </span>
                        <span class="navi-text">Ubah Soal</span>
                      </router-link>
                    </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-item">
                      <span
                        class="navi-link cursor-pointer"
                        @click="deleteSoal(data.item)"
                      >
                        <span class="navi-icon">
                          <i class="flaticon2-trash"></i>
                        </span>
                        <span class="navi-text">Hapus Soal</span>
                      </span>
                    </b-dropdown-text>
                  </div>
                  <!--end::Navigation-->
                </b-dropdown>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
        <!--        <b-card header-tag="header" footer-tag="footer">-->
        <!--          <template v-slot:header>-->
        <!--            <b-row>-->
        <!--              <b-col>-->
        <!--                <h6 class="mb-0">Daftar Soal</h6>-->
        <!--                <b-skeleton width="25%" v-if="isLoading"></b-skeleton>-->
        <!--                <span class="mb-0" v-if="!isLoading && currentSoalList[0]">{{ currentSoalList[0].ujian.nama }}</span>-->
        <!--              </b-col>-->
        <!--              <b-col cols="auto">-->
        <!--                <b-button pill variant="warning" class="mr-1" @click="$router.back()">Kembali</b-button>-->
        <!--                <b-button pill variant="primary" tag="router-link" :to="`/mapel/${idKurikulum}/ujian/${idUjian}/soal/create`">Tambah</b-button>-->
        <!--              </b-col>-->
        <!--            </b-row>-->
        <!--          </template>-->

        <!--          <b-button href="#" variant="primary">Go somewhere</b-button>-->
        <!--        </b-card>-->
        <b-modal
          v-model="modalJawaban"
          centered
          scrollable
          title="Pilihan Jawaban"
          hide-footer
        >
          <div class="jawaban">
            <div
              :class="`card card-custom card-stretch border ${
                jawaban.isCorrect
                  ? 'bg-light-primary text-primary border-primary'
                  : ''
              }`"
              v-for="(jawaban, i) in selectedSoal.answer"
              :key="`${selectedSoal.id}${i}`"
            >
              <div
                class="
                  card-body
                  d-flex
                  flex-row
                  p-4
                  justify-content-between
                  align-items-center
                "
              >
                <!--                <b-form-checkbox disabled :name="`jawaban${selectedSoal.id}`" v-model="jawaban.is_jawaban" size="lg" class="align-items-start cursor-pointer" v-if="selectedSoal.tipe=='multiple'"></b-form-checkbox>-->
                <!--                <b-form-radio :name="`jawaban${selectedSoal.id}`" :value="jawaban.id_jawaban" v-model="selectedSoal.id_jawaban" size="lg" class="align-self-start cursor-pointer" v-else></b-form-radio>-->
                <div v-html="jawaban.answer"></div>
                <div v-if="jawaban.point && jawaban.point > 0">
                  {{ jawaban.point }} pts
                </div>
              </div>
            </div>
          </div>
          <!--          <b-input-group class="mb-2" v-for="jawaban in selectedSoal.jawabans" :key="jawaban.id">-->
          <!--            <b-input-group-prepend is-text>-->
          <!--              <b-form-radio class="mr-n2" :options="jawaban" disabled :value="jawaban.id" value-field="id" :checked="selectedSoal.id_jawaban" name="pilihan_jawaban">-->
          <!--                <span class="sr-only">Radio for following text input</span>-->
          <!--              </b-form-radio>-->
          <!--            </b-input-group-prepend>-->
          <!--            <quill-editor-->
          <!--                class="form-control m-0 p-0"-->
          <!--                ref="deskripsiQuill"-->
          <!--                v-model="jawaban.jawaban"-->
          <!--                :options="editorOption"-->
          <!--                :disabled='true'-->
          <!--            />-->
          <!--          </b-input-group>-->
        </b-modal>
        <b-modal
          v-model="modalImport"
          id="modal-update"
          cancel-variant="outline-secondary"
          ok-title="Konfirmasi"
          cancel-title="Batalkan"
          centered
          @ok="importBankSoal"
          title="Import Data"
        >
          <validation-observer ref="formImport">
            <b-form id="form-upload-document" @submit.prevent="importBankSoal">
              <div class="d-flex flex-row mb-1">
                <b-button
                  href="/Sample Bank Soal.docx"
                  class="
                    btn btn-fh btn-light-info btn-hover-info
                    border border-info
                    font-weight-bold
                    px-2 px-lg-5
                  "
                >
                  <span class="svg-icon svg-icon-info svg-icon-lg">
                    <!--begin::Svg-->
                    <inline-svg src="media/svg/icons/General/Clip.svg" />
                    <!--end::Svg Icon-->
                  </span>
                  Sample Bank Soal.docx
                </b-button>
              </div>
              <b-form-group
                id="input-group-3"
                label="File (Word)"
                label-for="input-3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="File (Word)"
                  rules="required"
                >
                  <b-form-file
                    ref="file"
                    v-model="fileImport"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </validation-observer>
        </b-modal>

        <b-modal
          v-model="modalPreview"
          id="modal-update"
          cancel-variant="outline-secondary"
          ok-title="Import"
          cancel-title="Batalkan"
          centered
          @ok="importQuestion"
          :title="`Import ${questions.length > 0 ? questions.length : 0} soal`"
          modal-lg
        >
          <template v-if="questions.length > 0">
            <b-row>
              <b-col>
                <label>Topik</label>
                <br />
                <span
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-info
                    mr-1
                  "
                  v-for="topik in questions[selectedPReview].topic"
                  :key="topik"
                >
                  {{ topik }}
                </span>
              </b-col>
              <b-col>
                <label>Tipe soal</label>
                <br />
                <span
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-info
                    mr-1
                  "
                >
                  {{ questions[selectedPReview].type }}
                </span>
              </b-col>
            </b-row>

            <div class="text-dark-75 font-weight-bolder mb-1 font-size-lg">
              <br />
              Soal:
            </div>
            <div v-html="questions[selectedPReview].question"></div>

            <div
              class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
              v-if="questions[selectedPReview].type != 'essay'"
            >
              <br />
              Jawaban:
            </div>

            <b-row class="justify-content-between mr-1 ml-1">
              <b-col cols="6">
                <b-button
                  @click="selectedPReview = selectedPReview - 1"
                  v-if="selectedPReview > 0"
                  >Previous</b-button
                >
              </b-col>
              <b-col cols="6" class="row justify-content-end">
                <b-button
                  @click="selectedPReview = selectedPReview + 1"
                  v-if="selectedPReview < questions.length - 1"
                  >Next</b-button
                >
              </b-col>
            </b-row>
            <br v-if="onGoingSubmit" />
            <b-progress
              show-progress
              animated
              :max="questions.length"
              height="2rem"
              v-if="onGoingSubmit"
            >
              <b-progress-bar :value="progress">
                Sedang melakukan memasukkan soal:
                <strong
                  >{{ progress.toFixed(2) }} / {{ questions.length }}</strong
                >
              </b-progress-bar>
            </b-progress>
          </template>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import { GET_MAPEL } from "@/core/services/store/mapel.module";

import mixin from "@/core/services/Helpers";

import {
  DELETE_BANK_SOAL,
  GET_BANK_SOAL,
  IMPORT_BANK_SOAL,
  GET_QUESTION_TEMPLATE,
} from "@/core/services/store/banksoal.module";

import { CREATE_SOAL } from "@/core/services/store/soal.module";

import Swal from "sweetalert2";
export default {
  name: "BankSoalList",
  mixins: [mixin],
  data() {
    return {
      selectedPReview: 0,
      questions: [],
      onGoingSubmit: false,
      progress: 0,
      fileImport: null,
      modalImport: false,
      modalPreview: false,
      isLoading: false,
      selectedMapel: null,
      modalJawaban: false,
      selectedSoal: {},
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: null,
      editorOption: {
        modules: {},
        readOnly: true,
        theme: "bubble",
      },
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "question_code",
          label: "Kode Soal",
          sortable: true,
        },
        {
          key: "type",
          label: "Tipe Soal",
          sortable: true,
        },
        {
          key: "topic",
          label: "Topik Soal",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      tipeSoal: {
        pg: "Pilihan Ganda",
        multiple: "Jawaban Ganda",
        truefalse: "True/False",
        essay: "Essay",
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Daftar Bank Soal" }]);
    this.getData();
  },
  watch: {
    filter: function () {
      this.getData();
    },
    perPage: function () {
      this.getData();
    },
    currentPage: function () {
      this.getData();
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.banksoal.errors,
    }),
    ...mapGetters(["currentBankSoalList", "currentMapelList"]),
    idKurikulum() {
      return this.$route.params.idKurikulum;
    },
    idUjian() {
      return this.$route.params.idUjian;
    },
    options() {
      var opt = [];
      this.currentMapelList.forEach((mapel) => {
        opt.push({
          text:
            mapel.kurikulum.mapel.nama_mapel +
            " - Tingkat " +
            parseInt(mapel.kurikulum.semester / 2 + 1),
          value: mapel.id_kurikulum,
        });
      });
      return opt;
    },
    vendorId() {
      const user = this.$store.getters.authUser;
      return this.getVendorId(user);
    },
  },
  methods: {
    importQuestion(event) {
      event.preventDefault();

      this.onGoingSubmit = true;
      var answer = [];
      var isSuccess = 0;
      this.questions.forEach((item) => {
        if (item.type !== undefined) {
          this.progress = this.progress + 1;

          setTimeout(() => {
            const _data = {
              topic: item.topic,
              question: item.question,
              type: item.type,
              vendor_id: this.vendorId,
              answer: answer,
            };

            isSuccess++;

            this.$store
              .dispatch(CREATE_SOAL, _data)
              .then((response) => {})
              .catch((error) => {
                console.log(error);
              });
          }, 2000);
        }
      });

      if (this.progress == this.questions.length) {
        this.progress = 0;
        this.onGoingSubmit = false;

        this.modalPreview = false;

        Swal.fire({
          title: "",
          text: `Import ${isSuccess} soal dari ${this.questions.length} berhasil!`,
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });

        this.getData();
      }

      // this.progress = 0;
      this.onGoingSubmit = false;
    },
    downloadTemplate() {
      window.location.href = `${GET_QUESTION_TEMPLATE}${this.vendorId}`;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getMapel() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_MAPEL)
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Mata Pelajaran`,
            variant: "danger",
            solid: true,
          });
        });
    },
    getData() {
      this.isLoading = true;
      const param = new URLSearchParams();
      param.append("limit", this.perPage);
      param.append("page", this.currentPage);
      param.append("name", this.filter);

      this.$store
        .dispatch(GET_BANK_SOAL, param.toString())
        .then(() => {
          this.isLoading = false;
          this.totalRows = this.currentBankSoalList.total;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    importBankSoal() {
      this.$refs.formImport.validate().then(async (success) => {
        if (success) {
          const overlay = this.$loadingOverlay.show();
          this.$store
            .dispatch(IMPORT_BANK_SOAL, {
              file: this.fileImport,
              vendor_id: this.vendorId,
            })
            .then((response) => {
              overlay.hide();
              // Swal.fire({
              //   title: "",
              //   text: "Import bank soal berhasil!",
              //   icon: "success",
              //   confirmButtonClass: "btn btn-secondary",
              //   heightAuto: false,
              // });

              this.questions = response.data;

              this.modalImport = false;
              this.modalPreview = true;
            })
            .catch(() => {
              overlay.hide();
              this.$bvToast.toast(this.errors, {
                title: `Gagal Import Bank Soal`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    deleteSoal(item) {
      this.$bvModal
        .msgBoxConfirm("Apakah anda yakin ingin menghapus soal ini?", {
          title: "Konfirmasi",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Ya",
          cancelTitle: "Tidak",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isLoading = true;
            this.$store
              .dispatch(DELETE_BANK_SOAL, { id: item._id })
              .then((response) => {
                this.getData();
                this.$bvToast.toast(`Berhasil hapus soal`, {
                  title: response.message,
                  variant: "success",
                  solid: true,
                });
              })
              .catch(() => {
                this.isLoading = false;
                this.$bvToast.toast(this.errors, {
                  title: `Gagal Hapus Soal`,
                  variant: "danger",
                  solid: true,
                });
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {
          // An error occurred
        });
    },
    viewJawaban(item) {
      this.selectedSoal = item;
      this.modalJawaban = true;
    },
  },
};
</script>

<style scoped>
</style>
